import React, { Fragment } from "react";
import { Link } from "gatsby";

import Footer from "../homepage/footer";
import Lottie from "react-lottie";
import logoAnimationData from "../homepage/logo_animation.json";
import Seo from "../../seo";
import "../speakerdetails/styles.scss";
import { scheduleEventsDetails } from "../homepage/allstate.js";
import brand from "../homepage/images/logo.svg";
import arrow from "../homepage/images/enterprise-arrow.svg";
import speakersIllustration from "../homepage/images/speakers-illustration.svg";
import btnArrow from "../homepage/images/btn-arrow.svg";

const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/enterprise-conf.jpg",
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: logoAnimationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const TalkDetails = props => {
  const talkUrl = props.pageContext.slug;
  // const search = props.location.search;
  const currentTalkFilter = scheduleEventsDetails.filter(b => b.url === talkUrl);
  const currentTalks = currentTalkFilter[0];
  // const [isAliId, setIsAliId] = useState(false);
  // const [isLocalAliId, setIsLocalAliId] = useState(false);
  // const onSubmitCB = () => {
  //   if (typeof window !== undefined) {
  //     window.localStorage.setItem("aliIdConsent", "true");
  //   }
  // };
  // useEffect(() => {
  //   const searchParams = new URLSearchParams(search);
  //   const searchAliId = searchParams.get("aliId");
  //   if (searchAliId || searchAliId === "") {
  //     setIsAliId(true);
  //   }
  //   if (typeof window !== undefined) {
  //     if ("localStorage" in window && window.localStorage && "getItem" in window.localStorage) {
  //       const aliIdConsent = window.localStorage.getItem("aliIdConsent");
  //       if (aliIdConsent) {
  //         setIsLocalAliId(true);
  //       }
  //     }
  //   }
  // }, [search]);

  if (!currentTalks) {
    if (typeof window !== undefined) {
      window.location.href = "/404";
    }
  }

  return (
    <Fragment>
      <Seo
        title={currentTalks.metaTags.title}
        description={currentTalks.metaTags.description}
        meta={ogImage}
        canonicalLink={currentTalks.videoPageCanonical}
      />
      <section className="greenBgColor">
        <div className="mainWrapperSpeakers mainGridWrapperEnterprise removePaddBottom">
          <div>
            <div className="leftSidebarWrapper">
              <Link to="/enterprisegraphql/2020/">
                <div className="brand">
                  <Lottie
                    options={defaultOptions}
                    width={80}
                    style={{ margin: 0, marginLeft: "-10px" }}
                  />
                  <img src={brand} alt="Brand" />
                </div>
              </Link>
              <div className="mobile-dates visibleMobileEnterprise">
                21st &amp; 22nd
                <br />
                Oct’20
              </div>
            </div>
          </div>
          <img
            className="speakersDetailsIllustration"
            src={speakersIllustration}
            alt="Illustration"
          />
          <div className="speakersBottomSection removePaddTop">
            <div className="zIndexOne hiddenMobile">
              <div className="rotate">
                <div className="descEnterprise">{/*currentTalks.time*/}</div>
                <div className="sectionTitleEnterprise">{currentTalks.talkType}</div>
              </div>
            </div>
            <div className="zIndexOne">
              <div className="descEnterprise secondaryColor">{currentTalks.talkTitleType}</div>
              <div className="enterpriseTalkDetails">
                <h1 className="sectionTitleEnterprise primaryColor">{currentTalks.title}</h1>
                <div className="getCodeWrapperPadd">
                  <div className="videoWrapper">
                    <div className="videoAspectRatio">
                      <iframe
                        loading="lazy"
                        title={currentTalks.title}
                        src={currentTalks.videoLink}
                        frameBorder="0"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </div>
                {currentTalks.description ? (
                  <Fragment>
                    {currentTalks.description.lists.map((list, index) => {
                      return (
                        <div key={index} className="descEnterprise secondaryColor enterprisewd65">
                          {list}
                        </div>
                      );
                    })}
                    {currentTalks.description.subLists ? (
                      <ul className="descEnterprise listsPaddTop enterprisewd65">
                        {currentTalks.description.subLists.map((subList, subIndex) => {
                          return (
                            <li key={subIndex}>
                              <div className="grayCircle"></div>
                              {subList}
                            </li>
                          );
                        })}
                      </ul>
                    ) : null}
                    {currentTalks.description.listsTwo
                      ? currentTalks.description.listsTwo.map((list, listIndex) => {
                          return (
                            <div key={listIndex} className="descEnterprise enterprisewd65">
                              {list}
                            </div>
                          );
                        })
                      : null}
                  </Fragment>
                ) : null}
              </div>
              <div className="enterpriseSpeakersWrapper enterprisewd65 enterpriseBorTopWhite">
                <div className="sectionTitleEnterprise">
                  {currentTalks.multipleSpeakers ? "Speakers" : "Speaker"}
                </div>
                <div className="enterpriseSpeakersListWrapper twoColumsWrapper">
                  {currentTalks.speakersList.map((list, index) => {
                    return (
                      <div key={index} className="speakersList twoColumsList">
                        <Link to={list.speakersUrl}>
                          <div className="speakersListContainer">
                            <div className="speakersImg">
                              <img src={list.speakersImg} alt={list.speakersName} />
                            </div>
                            <div className="speakersProfileWrapper">
                              <div className="subTitleEnterprise">{list.speakersName}</div>
                              <div className="descSmallEnterprise">{list.speakersDesignation}</div>
                            </div>
                            <div className="enterpriseArrow">
                              <img src={arrow} alt="Arrow" />
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="eventsBtnWrapper">
                <div className="enterpriseLine"></div>
                <Link to="/enterprisegraphql/2020/#schedule">
                  <button className="eventsBtn">
                    <img src={btnArrow} alt="Arrow" />
                    Back to Talks
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer bgColor="greenBgColor" />
    </Fragment>
  );
};

export default TalkDetails;
